<template>
  <div>
    <WsCreate
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="_pageSettingSecondary"
      :extendPostData="_extendPostData"
      v-model="updateData"
      :emitInput="true"
    >
    </WsCreate>
  </div>
</template>

<script>
import S_Stone_Wasa from "@/__vue2stone_cms/service/wasa";
import model from "@/__vue2stone_cms/models/area";
export default {
  metaInfo() {
    return {
      title: `新增${this.label}`,
    };
  },

  data: () => ({
    updateData: null,
    modelName: model.modelName,
    urlModelName: model.urlModelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name"],
            },
          ],
        },
      ],
    },
  }),

  computed: {
    _extendPostData() {
      if (this.$store.state.auth.currentUser.country_code) {
        return {
          country_code: this.$store.state.auth.currentUser.country_code,
        };
      } else {
        return {};
      }
    },
    _pageSettingSecondary() {
      const fields = ["sequence"];
      if (S_Stone_Wasa.hasScope(["boss"])) {
        if (this.$config.wsmodule.country_code) {
          fields.push("country_code");
        }
      }
      return [
        {
          type: "stateCard",
          floors: [
            {
              title: "setting",
              titleInLocale: true,
              fields: fields,
            },
          ],
        },
      ];
    },
  },
};
</script>

<style>
</style>